import { useState, useEffect } from 'react';
import Api from '../services/api';
import useAuth from '../utils/auth';
import { TableProps, User } from '../types/global';
import ViewerPanel from '../components/settings/viewerPanel';
import SuperAdminDashboard from '../components/settings/superAdminPanel';
import AdminDashboard from '../components/settings/orgAdminPanel';

export default function Settings() {
  const [organisationUsers, setOrganisationUsers] = useState<Array<TableProps>>([]);
  const [user, setUser] = useState({});

  const authUser = useAuth();

  useEffect(() => {
    const getOrganisationUsers = async () => {
      try {
        const response = await Api.get('/api/organisation-users');
        if (!Array.isArray(response)) return;
        const currentUser = response.find((responseUser: User) => responseUser.email === authUser.email);
        if (currentUser) {
          setUser({
            email: currentUser.email,
            'last name:': currentUser.last_name,
            'first name': currentUser.first_name,
            role: currentUser.role,
          });
        }
        const formattedResponse = response.filter((userResponse: User) => userResponse.email !== authUser.email).map((userResponse: any) => ({
          email: userResponse.email,
          'last name:': userResponse.last_name,
          'first name': userResponse.first_name,
          role: userResponse.role,
        }));
        setOrganisationUsers(formattedResponse);
      } catch (error) {
        if (error instanceof Error) {
          throw new Error("False to fetch organisation's users");
        }
      }
    };

    getOrganisationUsers();
  }, [authUser.email]);
  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      {Object.keys(user).length > 0 ? <ViewerPanel user={user} /> : ''}
      <AdminDashboard tableData={organisationUsers} />
      <SuperAdminDashboard />
    </div>

  );
}
