import { create } from 'zustand';
import { CampaignData, CampaignInformation } from '../../types/global';
import { snakeCase } from '../../utils/formatters';
import Api from '../../services/api';
import { Channel } from '../../types/enums';

interface CampaignStoreState {
  campaigns: CampaignData[];
  selected: CampaignInformation;
  setCampaigns: (campaigns: CampaignData[]) => void;
  addCampaign: (campaign: CampaignData) => void;
  setSelected: (title: string, value: string | boolean) => void;
  fetchTemplate: (campaignId:string) => Promise<void>;
  setTemplate: (campaignId: string, tempalteId: string | number) => void;
}

const useCampaignStore = create<CampaignStoreState>((set, get) => ({
  campaigns: [],
  selected: {
    campaignType: '',
    communicationChannel: '',
    campaignName: '',
    snapshot: '',
    excludeHouseholds: false,
    stagger: false,
  },
  content: null,
  smsTemplateId: null,
  letterTemplateId: null,
  loading: false,
  setCampaigns: (campaigns) => set(() => ({
    campaigns,
  })),
  addCampaign: (campaign) => set((state) => ({
    campaigns: [campaign, ...state.campaigns],
  })),
  setSelected: (title, value) => set((state) => ({
    selected: { ...state.selected, [title]: value },
  })),
  fetchTemplate: async (campaignId) => {
    const { campaigns } = get();
    set({ campaigns: campaigns.map((campaign) => (campaign.id === campaignId ? { ...campaign, isLoadingTemplate: true } : campaign)) });
    try {
      let response;
      const campaign = campaigns.find((c) => c.id === campaignId);
      if (!campaign) throw new Error('Campaign not found');
      if (campaign.campaignInformation.communicationChannel === Channel.SMS) {
        response = await Api.get('/api/all-sms-templates');
      } else {
        response = await Api.post('/api/all-letter-templates', { benefit_name: snakeCase(campaign.campaignInformation.campaignType) });
      }
      set({
        campaigns: campaigns.map((_campaign) => (_campaign.id === campaignId
          ? {
            ...campaign,
            content: response,
            isLoadingTemplate: false,
          }
          : campaign)),
      });
    } catch (error) {
      set({
        campaigns: campaigns.map((campaign) => (campaign.id === campaignId
          ? {
            ...campaign,
            content: null,
            isLoadingTemplate: false,
          }
          : campaign)),
      });
      throw new Error('Failed to fetch pdf template');
    }
  },
  setTemplate: (campaignId, templateId) => {
    const { campaigns } = get();

    const campaign = campaigns.find((c) => c.id === campaignId);
    if (!campaign) throw new Error('Campaign not found');
    if (campaign.campaignInformation.communicationChannel === Channel.SMS) {
      set({
        campaigns: campaigns.map((c) => (c.id === campaignId
          ? { ...campaign, smsTemplateId: templateId as number } : campaign)),
      });
    } else {
      set({
        campaigns: campaigns.map((campaignWithLetterTemplateId) => (campaignWithLetterTemplateId.id === campaignId
          ? { ...campaign, letterTemplateId: templateId as string } : campaign)),
      });
    }
  },
}));

export default useCampaignStore;
