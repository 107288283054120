import { useState } from 'react';
import customIcons from '../utils/icons';

export default function InfoBubble({ info }:{ info:string }) {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <div className="flex flex-col h-full items-center relative z-50 p-2">
      <button
        type="button"
        id="info-icon"
        aria-label="click enter for more information"
        className="text-pipstatus-info text-lg"
        onKeyDown={(event) => (event.key === 'Enter' ? setShowInfo(true) : setShowInfo(false))}
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
      >
        {customIcons.infoIcon}
      </button>
      {showInfo && (
        <div className="absolute z-50 top-full mt-1 -right-2 bg-pipstatus-infoLight rounded border border-pipstatus-info p-1 w-80 max-w-[300px]">
          <p className="m-2 font-thin text-black">
            {info}
          </p>
        </div>
      )}

    </div>

  );
}
