/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */
import { Form, redirect, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SplitLayout from '../components/splitLayout';
import List from '../components/campaigns/list';
import LoadingModal from '../components/loadingModal';
import { CampaignData, LetterTemplate, SMSTemplate } from '../types/global';
import Modal from '../components/modal';
import customIcons from '../utils/icons';
import Steps from '../components/campaigns/steps';
import Api from '../services/api';
import campaignSteps, { sendLetterStep } from '../utils/campaignSteps';
import useCampaignStore from '../components/campaigns/campaignStore';
import { getCampaign } from '../utils/campaign';
import { formatSnapshot } from '../utils/formatters';
import { Channel } from '../types/enums';
import SmallButton from '../components/smallButton';
import SMS from '../components/campaign-confirmation/smsPreview';
import LetterPreview from '../components/campaign-confirmation/letterPreview';
import SMSGallery from '../components/campaign-confirmation/smsGallery';

export async function action({ params }:{ params:{ campaignId: string, impactTheme: string } }) {
  const { campaignId } = params;
  try {
    const postData = getCampaign(campaignId);
    await Api.post('/api/add-campaign', postData);
    return redirect('/campaigns');
  } catch (error) {
    console.error('error:', 'failed to fetch data or redirect');
    return null;
  }
}

export default function CampaignConfirmation() {
/*
campaigns = array of all campaigns store in state store
campaign data = ALL campaign info that is collected and generated (generated being the id and created at date)
campaign information = is the campaign info that is collected from the user
*/
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSms, setShowSms] = useState(false);
  const [smsText, setSmsText] = useState('');
  const [showLetter, setShowLetter] = useState(false);
  const [letterUrl, setLetterUrl] = useState('');

  const params = useParams();
  const campaigns = useCampaignStore((state) => state.campaigns);
  const fetchTemplate = useCampaignStore((state) => state.fetchTemplate);
  const setTemplate = useCampaignStore((state) => state.setTemplate);

  const campaignData = campaigns.find((campaign) => campaign.id === params.campaignId) as CampaignData;
  const displayExcludeHouseholds = false;
  const {
    campaignName, campaignType, snapshot, excludeHouseholds, excludeHouseholdsByMonth, communicationChannel, stagger,
  } = campaignData.campaignInformation;

  const campaign = {
    'Campaign Name': campaignName,
    'Campaign Type': campaignType,
    'LIFT Snapshot': formatSnapshot(snapshot),
  };
  const titleObject = {
    title: 'Campaign Manager',
    icon: customIcons.sendIcon,
  };

  const steps = [...campaignSteps, sendLetterStep];
  const modalMessage = `This campaign will be be sent to Policy in Practice and drafts generated for review.
  We will be in touch to confirm details of the campaign before it is sent to residents. Would you like to proceed?`;

  const handleModalOpen = () => {
    setModalOpen((previousState) => !previousState);
  };
  const handleLoading = () => {
    setLoading(true);
  };
  const excludeHouseholdsText = (numberOfMonths: string) => (
    <>
      Households contacted in the last
      <strong>{` ${numberOfMonths?.toLowerCase()}`}</strong>
      will be excluded`
    </>
  );
  const includeHouseholdsText = 'Included previously contacted households';
  const handleSms = (template: SMSTemplate) => {
    setTemplate(campaignData.id, template.id);
    setSmsText(template.template);
    setShowSms(true);
  };

  const handleLetter = (event: any, campaignId: string) => {
    const value = JSON.parse(event.target.value);

    const { id, url } = value;

    setTemplate(campaignId, id);
    setLetterUrl(url);
    setShowLetter(true);
  };

  useEffect(() => {
    if (campaignData && !campaignData.content) {
      fetchTemplate(campaignData.id);
    }
  }, [campaignData?.id]);
  if (campaignData?.isLoadingTemplate) {
    return <LoadingModal message="loading" />;
  }
  // function to conditionally render content based on loading state and modals
  const renderContent = () => {
    if (isModalOpen) {
      if (loading) {
        return (<LoadingModal message="loading" />);
      }

      return (
        <Modal isOpen={isModalOpen} hasCloseBtn onClose={handleModalOpen}>
          <span className="text-6xl text-pipgreen">{customIcons.mailWarningIcon}</span>
          <h2 className="text-xl font-bold text-pipgreen-dark">Please Confirm</h2>
          <p className="font-thin whitespace-wrap">{modalMessage}</p>
          <div className="w-full flex justify-between p-2">
            <button
              type="button"
              id="cancelModalButton"
              className="p-1 w-1/2 m-2 shadow-inner bg-white rounded-md text-pip-background border"
              onClick={handleModalOpen}
            >
              Cancel
            </button>
            <Form method="post" className="w-1/2" onSubmit={handleLoading} replace>
              <button
                type="submit"
                className="w-full p-1 m-2 bg-pipgreen rounded-md text-white"
              >
                Send for Review
              </button>
            </Form>
          </div>
        </Modal>
      );
    } return (
      <>
        <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Preview Campaign</h1>
        <SplitLayout leftWeight="" rightWeight="">

          <Steps
            titleObject={titleObject}
            steps={steps}
            selected={{
              campaignName, campaignType, snapshot, excludeHouseholds, communicationChannel, stagger,
            }}
          />

          <div className="flex flex-grow flex-col overflow-scroll">
            <List campaign={campaign} />
            {displayExcludeHouseholds && (
            <p className="text-sm text-center">
              {excludeHouseholds && excludeHouseholdsByMonth ? excludeHouseholdsText(excludeHouseholdsByMonth) : includeHouseholdsText}
            </p>
            )}
            {!showSms && communicationChannel === Channel.SMS ? (
              <SMSGallery smsTemplateList={campaignData.content as SMSTemplate[]} handleSms={handleSms} />) : ''}
            {showSms && communicationChannel === Channel.SMS ? <SMS sender="policyinpractice" message={smsText} /> : ''}
            {!showSms && communicationChannel === Channel.Letter && campaignData.content ? (
              <div>
                <label htmlFor="letter-template-dropwdown" className="flex flex-col p-2 justify-between font-thin w-full">
                  <span className="text-sm">Choose Letter Template</span>
                  <select
                    id=""
                    name=""
                    onChange={(e) => handleLetter(e, campaignData.id)}
                    className="flex-1 p-1 rounded-md align-middle bg-slate-50 border border-pipgrey-light text-pipgrey"
                  >
                    <option value="">Please select option</option>
                    {(campaignData.content as LetterTemplate[]).map((letterTemplate: LetterTemplate) => (
                      <option
                        key={letterTemplate.id}
                        value={JSON.stringify({
                          id: letterTemplate.id,
                          url: letterTemplate.letter_template_signed_url,
                        })}
                      >
                        {letterTemplate.id}
                      </option>

                    ))}
                  </select>
                </label>
              </div>
            ) : ''}
            {showLetter && communicationChannel === Channel.Letter ? <LetterPreview url={letterUrl} /> : ''}
          </div>
        </SplitLayout>
        <div className="w-full m-2 flex justify-center">
          {campaignData.content ? <SmallButton isGreen text="Send for Review" onClick={handleModalOpen} /> : ''}
        </div>
      </>

    );
  };
  return (
    <div className="h-screen flex flex-col w-full p-4">
      {renderContent()}
    </div>
  );
}
